import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import SVG from 'react-inlinesvg'

import Layout from '../../Layout'
import SEO from '../../components/SEO'

import AsideImage from '../../components/AsideImage/AsideImage'
import Button from '../../components/Button/Button'

import { uslugiItems } from './../../constants/uslugiItems'

const StyledIcon = styled(SVG)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  margin: 30px 0;
  svg {
    width: 100%;
    height: 100%;
  }
`

const UslugiTemplatePage = ({ data }) => {
  const contentData = uslugiItems[1]
  return (
    <Layout
      header={contentData.header}
      asideContent={[
        <AsideImage
          key="asideImage"
          srcImage={data.coverPhoto.childImageSharp.fluid}
        >
          <Button to="https://iqdental.app/rejestracja-wizyty/6b3cd188-16dc-42ad-91ae-4a073eb8795a/new">
            Umów wizytę
          </Button>
        </AsideImage>,
      ]}
    >
      <SEO
        title={`${contentData.header} | Gabinet Stomatologiczny Glossy Dental | Stomatolog Dentysta Nadarzyn`}
      />
      <StyledIcon src={contentData.icon} />
      {contentData.content}
      <p>
        <Button to="/uslugi" small>
          &larr; Usługi
        </Button>
      </p>
    </Layout>
  )
}

export default UslugiTemplatePage

export const pageQuery = graphql`
  query {
    coverPhoto: file(relativePath: { eq: "image3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
